import {Centrifuge} from 'centrifuge';
import configProvider from '@common/services/configProvider';

const centrifugeClientFactory = (token: string, getToken = null) => {
    const urlRealtime = configProvider.get('REALTIME_URL');
    const centrifuge = new Centrifuge(urlRealtime, {
        token,
        getToken,
    });
    return centrifuge;
};

export default centrifugeClientFactory;